//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isRichtextFilled } from '~/utils/helpers';
import { CollapseTransition } from 'vue2-transitions';
import IconCross from '~/assets/img/icons/cross.svg?inline';

export default {
  name: 'BlokHomeAbout',
  components: {
    CollapseTransition,
    IconCross,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showPopup: false,
      isVisibleAos: false,
      showFold: false,
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    lazyStrategy() {
      if (this.index === 0) {
        return {
          'data-not-lazy': '',
        };
      }

      return null;
    },
  },
  methods: {
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    handleShowPopup() {
      this.showPopup = true;
    },
    handleClosePopup() {
      this.showPopup = false;
    },
    handleToggleFold() {
      this.showFold = !this.showFold;
    },
    isRichtextFilled(doc) {
      return isRichtextFilled(doc);
    },
  },
}
